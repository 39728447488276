/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLocalizationCommand } from './CreateLocalizationCommand';
export type CreateCustomerProfileCommand = {
  name: string;
  abbreviation: string;
  profileNumber: number;
  ageFrom?: number;
  /**
   * Represents if colliding ticket fares selling is forbidden.
   */
  checkTicketCollision: boolean;
  ageTo?: number;
  /**
   * Profile valid from date. Type: 'DATE'
   */
  validFrom?: string;
  /**
   * Profile valid to date. Type: 'DATE'
   */
  validTo?: string;
  orderNumber: number;
  primary: boolean;
  requireConfirmation: boolean;
  /**
   * Customer profile type. Personal or impersonal.
   */
  type: CreateCustomerProfileCommand.type;
  /**
   * Default valid to when setting this profile.
   */
  validToDefault?: CreateCustomerProfileCommand.validToDefault;
  /**
   * Customer profile identifier. Used for copying. Type: 'UUID'
   */
  oldId?: string;
  /**
   * Names of card application attachment types to assign to the new customer profile.
   */
  cardApplicationAttachmentTypeNames?: Array<any>;
  localizations: Array<CreateLocalizationCommand>;
  isic: boolean;
  /**
   * Discount id for isic card validation from GTS ALIVE. Must be set if isic is true
   */
  discountId?: number;
  firstApplicationForFree?: boolean;
  /**
   * Client current timezone offset in minutes. Is required.
   */
  timezoneOffset?: number;
};
export namespace CreateCustomerProfileCommand {
  /**
   * Customer profile type. Personal or impersonal.
   */
  export enum type {
    IMPERSONAL = 'IMPERSONAL',
    PERSONAL = 'PERSONAL',
  }
  /**
   * Default valid to when setting this profile.
   */
  export enum validToDefault {
    CARD_VALIDITY = 'CARD_VALIDITY',
    END_OF_YEAR = 'END_OF_YEAR',
    END_OF_JANUARY = 'END_OF_JANUARY',
    END_OF_AUGUST = 'END_OF_AUGUST',
    DAY_BEFORE_19_BIRTHDAY_OR_END_OF_AUGUST = 'DAY_BEFORE_19_BIRTHDAY_OR_END_OF_AUGUST',
    TO_AGE = 'TO_AGE',
    _365_DAYS_AFTER_CONFIRMATION_DELIVERY = '365_DAYS_AFTER_CONFIRMATION_DELIVERY',
    END_OF_SEPTEMBER = 'END_OF_SEPTEMBER',
    TO_DEFINED_YEARS = 'TO_DEFINED_YEARS',
  }
}
